:root {
    /* colors */
    --md-color-white: #FFFFFF;
    --md-color-off-white: #f7f7f7;
    --md-color-blur-white: #e7e7e7;
    --md-color-green: #358734;
    --md-color-red: #C60000;
    --md-color-blue: #2B6D9F;
    --md-color-blue-variant: #2A6FA1;
    --md-color-gray: #9F9F9F;
    --md-color-dark-blue: #2B2D42;
    --md-color-divider-gray: #F0F0F0;
    --md-color-dark-gray: #575757;
    --md-color-scrollbar-gray: #D8D8D8;
    --md-color-black: #000000;
    --md-color-light-grey: #757575;
    --md-color-light-grey-hard: #7D7D7D;
    --md-color-chart-border: #2B6D9F;
    --md-color-dark-blue-solid: #2578AD;
    --md-color-light-blue: #e9f1f8;
    --md-color-gray-background: #FAFAFA;
    --md-color-yellow: #e3e36f;
    --md-color-dark-yellow: #e4b90e;
    --md-color-light-yellow: #f1f1ce;
    --md-color-light-blue-variant: #629AC4;
    --md-color-dull-blue: #37729E;
    --md-color-mid-blue: #1D4F75;
    --md-color-blue-solid: #002B4C;
    --md-color-light-white-background: #F6FBFF;
    --md-color-dark-white: #f1f1f1;
    --md-color-light-blur-gray: #cccccc;
    --md-color-blur-gray: #888888;
    --md-color-dark-blur-gray: #555555;
    --md-color-chevron-blue: #0075BE;
    --md-color-disable-grey: #C4C4C4;
    --md-color-red-brown: #7d324c;
    --md-color-light-green: #00b3a1;
    --md-color-sea-green: #008338;

}