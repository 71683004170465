:root {
    /* fonts sizes */
    --md-font-size-small: 12px;
    --md-font-size-regular: 14px;
    --md-font-size-large: 16px;
    --md-font-size-extra-large: 20px;
    --md-font-size-extra-jumbo: 24px;
    --md-font-size-extra-jumbo-large: 32px;

    /* font families */
    --md-font-family-helvetica-neue-regular: 'HelveticaNeue-Regular';
    --md-font-family-helvetica-neue-medium: 'HelveticaNeue-Medium';
    --md-font-family-helvetica-neue-bold: 'HelveticaNeue-Bold';
    --md-font-family-palatino-regular: 'Palatino-Regular';
    --md-font-family-palatino-bold: 'Palatino-Bold';
}