@font-face {
    font-family: 'HelveticaNeue-Regular';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('../assets/fonts/HelveticaNeue-Regular.woff2') format('woff2');
}

@font-face {
    font-family: 'HelveticaNeue-Medium';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url('../assets/fonts/HelveticaNeue-Medium.woff2') format('woff2');
}

@font-face {
    font-family: 'HelveticaNeue-Bold';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('../assets/fonts/HelveticaNeue-Bold.woff2') format('woff2');
}

@font-face {
    font-family: 'Palatino-Regular';
    font-weight: 400;
    font-display: swap;
    font-style: normal;
    src: url('../assets/fonts/Palatino-Regular.woff2') format('woff2');
}